import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/top.jpg'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
            navBarActiveClass: 'is-active',
          })
          : this.setState({
            navBarActiveClass: '',
          })
      }
    )
  }

  render() {
    return (
      <>
        <nav
          className="navbar is-transparent is-hidden-desktop"
          role="navigation"
          aria-label="main-navigation"
        >
          <div className="container">
            <div className="navbar-brand">
              <Link to="/" className="navbar-item" title="Logo">
                <img src={logo} alt="Kaldi" style={{ width: '88px' }} />
              </Link>
              {/* Hamburger menu */}
              {/* <div
                className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                data-target="navMenu"
                onClick={() => this.toggleHamburger()}
              >
                <span />
                <span />
                <span />
              </div> */}
            </div>
            <div
              id="navMenu"
              className={`navbar-menu ${this.state.navBarActiveClass}`}
            >
              <div className="navbar-start has-text-centered">
                {/* <Link className="navbar-item" to="#about">
                  About
              </Link>
                <Link className="navbar-item" to="#values">
                  Values
              </Link> */}
              </div>
            </div>
          </div>
        </nav>
        <nav
          className="navbar is-transparent is-hidden-touch"
          role="navigation"
          aria-label="main-navigation"
        >
          <div className="container">
            <div
              className='navbar-menu is-flex'
            >
              <div className="navbar-left has-text-centered">
                {/* <Link className="navbar-item" to="/about">
                  About
                </Link> */}
              </div>
              <div className='navbar-brand'>
                <Link to="/" className="navbar-item" title="Logo">
                  <img src={logo} alt="People for Pothoff" style={{ width: '110px' }} />
                </Link>
              </div>
              <div className='navbar-right has-text-centered'>
                {/* <Link className="navbar-item" to="/blog">
                  Values
                </Link> */}
              </div>
            </div>
          </div>
        </nav>
        {/* <div className="container">
          <div className='columns'>
            <div className='column mt-1 mb-1 has-text-centered'>
              <Link to="/" className="">Home</Link>
              &nbsp;|&nbsp;
              <Link to="/events" className="">Events</Link>
              &nbsp;|&nbsp;
              <Link to="/" className="">Videos</Link>
              </div>
          </div>
        </div> */}
      </>
    )
  }
}

export default Navbar
